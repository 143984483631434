<script>
/* eslint-disable */

</script>
<template>
  <b-sidebar
    id="add-product-to-group"
    ref="add-product-to-group"
    v-model="show"
    sidebar-class="sidebar-60"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
    @hidden="(() => isSingleProduct = false)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide()"
          />
        </div>
      </div>
      <b-row class="m-1">
        <b-col cols="12">
          <ejs-grid
            id="overviewgridProdottiMagazzino"
            ref="overviewgridProdottiMagazzino"
            locale="it-IT"
            :data-source="products"
            :selection-settings="selectionSettings"
            :edit-settings="editSettings"
            :page-settings="pageSettings"
            :allow-filtering="true"
            :allow-editing="false"
            :filter-settings="filterOptions"
            :show-column-chooser="true"
            :allow-selection="true"
            :allow-sorting="true"
            :allow-resizing="true"
            :enable-virtualization="false"
            :allow-row-drag-and-drop="false"
            height="550"
            row-height="38"
            :enable-hover="false"
            :enable-header-focus="true"
            :toolbar="toolbar"
            :toolbar-click="toolbarClick"
            :allow-grouping="false"
            :allow-paging="true"
            :action-begin="actionTableBegin"
            :action-complete="actionTableComplete"
            :command-click="commandColumm"
            :row-selected="onRowSelected"
            :cell-deselecting="onDeselectRow"
          >
            <e-columns>
              <e-column
                type="checkbox"
                :allow-filtering="false"
                :allow-sorting="false"
                width="70"
              />
              <e-column
                field="id"
                header-text="id"
                :is-primary-key="true"
                width="200"
                :visible="false"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="codice"
                header-text="Codice"
                width="100"
                :filter="filter"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="nome"
                header-text="Nome"
                width="200"
                :filter="filter"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="quantita"
                header-text="Quantità"
                width="200"
                :filter="filter"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="prezzoVendita"
                header-text="Prezzo"
                width="200"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="aliquotaIva"
                header-text="Iva"
                width="200"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="costo"
                header-text="Costo"
                width="200"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="unitaUtilizzo"
                header-text="Unità Utilizzo"
                width="200"
                :visible="false"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="unitaMisura"
                header-text="UM"
                width="200"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="statoServizio"
                header-text="Stato"
                width="170"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="tipoProdotto"
                header-text="Tipologia"
                width="140"
                :filter="filter"
              />
              <e-column
                field="attivo"
                header-text="Attivo"
                width="100"
                :filter="filter"
                :template="templateAttivo"
                text-align="Center"
                :visible="false"
              />
              <e-column
                field="disponibile"
                header-text="Disponibile"
                width="100"
                :filter="filter"
                :template="templateDisponibile"
                text-align="Center"
              />
              <e-column
                field="inEvidenza"
                header-text="In Evidenza"
                width="100"
                :filter="filter"
                :template="templateInEvidenza"
                text-align="Center"
                :visible="false"
              />
              <e-column
                field="visibile"
                header-text="Visibile"
                width="100"
                :filter="filter"
                :template="templateVisibile"
                text-align="Center"
                :visible="false"
              />
              <e-column
                field="descrizioneBreve"
                header-text="Descrizione"
                width="170"
                :filter="filter"
                :visible="false"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="note"
                header-text="Note"
                width="150"
                :filter="filter"
                :visible="false"
                clip-mode="EllipsisWithTooltip"
              />
              <e-column
                field="Azioni"
                :lock-column="true"
                header-text="Azioni"
                width="150"
                :allow-editing="false"
                :commands="commands"
                :allow-sorting="false"
                :allow-filtering="false"
                hide-at-media="(min-width:900px)"
              />
            </e-columns>
          </ejs-grid>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BRow, BCol, BAvatar, // BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, Toolbar, Page, ColumnChooser, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base'
// import { getProdotti } from '@/@core/api-service/catalog/products'
import { getProdottiMagazzino } from '@/@core/api-service/catalog/products'

setCulture('it-IT')
L10n.load(italian)

Vue.use(GridPlugin)

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    // BButton,
  },
  props: {
    idProductLocked: {
      type: Object,
      required: false,
    },
  },
  watch: {
    show(newV, _) {
      if(newV) {
        getProdottiMagazzino([{ idMagazzino: this.magazzinoProdottiId}]).then(res => {
          if (res.data.esito === 'OK') {
            this.products = [...res.data.prodotti]
            if(this.$route.name === 'details-products') {
              this.products = this.products.filter(el => el.id === this.idProductLocked.id)

            }
          }
        })
      }
    },
  },
  data() {
    return {
      magazzinoProdottiId: '',
      products: [],
      selectedRow: [],
      selectionOptions: { mode: 'Cell' },
      commands: [        
        {
          type: 'Aggiungi alla lista',
          id: 'addProduct',
          target: '.e-column',
          buttonOption: { iconCss: 'e-icons e-plus', cssClass: 'e-flat' },
        },
      ],
      show: false,
      title: 'Seleziona Prodotti',
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true, mode: 'Cell' },
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      toolbar: ['ColumnChooser', 'Print',
        {
        text: 'Aggiungi Selezionati', tooltipText: 'Aggiungi Selezionati', prefixIcon: 'e-expand', id: 'addProduct',
        }
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      rowsSelected: [],
      showButtonsOption: false,
      searchTerm: '',
      pageLength: 10,
      dir: false,
      rows: [
        {
          id: 1,
          /* eslint-disable global-require */
          name: 'Croccantini',
          type: 'Mangimi',
          description: 'Croccantini vegani per cani diabetici',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'kg',
          quantity: '12',
          codProducts: '0008',
          activated: 'Active',
          producter: ' VETtop',
          supplier: 'Royal',
          price: '20',
          cost: '16',
          disponibile: 'Yes',
        },
        {
          id: 2,
          name: 'Guanti Veterinario',
          type: 'Generico',
          description: 'Guanto veterinario trasparente monouso.',
          brand: 'Royal Canin',
          category: 'Consumabili',
          unit: 'pz',
          quantity: '100',
          codProducts: '0001',
          activated: 'Active',
          producter: ' VETtop',
          supplier: 'GinoVET',
          price: '19.50',
          cost: '12.00',
        },
        {
          id: 3,
          name: 'Rimuovi Pelo',
          type: 'Generico',
          description: 'Crema rimuovi pelo per gatto',
          brand: 'Royal Canin',
          category: 'Consumabili',
          unit: 'gr',
          quantity: '50',
          codProducts: '0002',
          activated: 'Active',
          producter: ' VETtop',
          supplier: 'GinoVET',
          price: '15.50',
          cost: '12.00',
        },
        {
          id: 4,
          name: 'Set Diagnostica',
          type: 'Medico',
          description: 'Set prima diagnostica basic',
          category: 'Generico',
          brand: 'Royal Canin',
          unit: 'pz',
          quantity: '1',
          codProducts: '0003',
          activated: 'Active',
          producter: ' XENON',
          supplier: 'XENON',
          price: '29.50',
          cost: '12.00',
        },
        {
          id: 5,
          name: 'Coperta Veterinaria',
          type: 'Generico',
          description: 'Coperta riscaldante per animali usa e getta.',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'pz',
          quantity: '1',
          codProducts: '0004',
          activated: 'Active',
          producter: ' Hunter Green',
          supplier: 'GinoVET',
          price: '9',
          cost: '4.2',
        },
        {
          id: 6,
          name: 'Guanti Veterinario',
          type: 'Generico',
          description: 'Guanto veterinario trasparente monouso.',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'pz',
          quantity: '100',
          codProducts: '0005',
          activated: 'Active',
          producter: ' VETtop',
          supplier: 'GinoVET',
          price: '19.50',
          cost: '12.00',
        },
        {
          id: 7,
          name: 'Gel per Veterinari',
          type: 'Consumabili',
          description: 'Gel per veterinari, generico',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'ml',
          quantity: '5000',
          codProducts: '0006',
          activated: 'Active',
          producter: ' VETgel',
          supplier: 'GinoVET',
          price: '19.50',
          cost: '12.00',
        },
        {
          id: 8,
          name: 'Bocconcini veg',
          type: 'Mangimi',
          description: 'Bocconcini gustosi',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'gr',
          quantity: '60',
          codProducts: '0007',
          activated: 'Active',
          producter: ' Herpless',
          supplier: 'GinoVET',
          price: '10.50',
          cost: '7.5',
        },
        {
          id: 9,
          name: 'Collare antiparassitario',
          type: 'Generico',
          description: 'Collare veterinario trasparente antiparassiti per gatti.',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'pz',
          quantity: '1',
          codProducts: '0009',
          activated: 'Active',
          producter: ' VETtop',
          supplier: 'GinoVET',
          price: '19.50',
          cost: '12.00',
        },
        {
          id: 10,
          name: 'Barattolo Compresse',
          type: 'Parafarmaco',
          description: 'Compresse barattolo',
          category: 'Consumabili',
          brand: 'Royal Canin',
          unit: 'pz',
          quantity: '240',
          codProducts: '0010',
          activated: 'Active',
          producter: ' Confis',
          supplier: 'GinoVET',
          price: '111.68',
          cost: '70.50',
        },
      ],
      templateAttivo() {
        return {
          template: Vue.component('templateAttivo', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.attivo == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.attivo == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateDisponibile() {
        return {
          template: Vue.component('templateDisponibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.disponibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.disponibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateInEvidenza() {
        return {
          template: Vue.component('templateInEvidenza', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.inEvidenza == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.inEvidenza == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateVisibile() {
        return {
          template: Vue.component('templateVisibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.visibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.visibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
    }
  },
  computed: {
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const tradeData = []
      const tipoMovimento = ['Carica', 'Scarica', 'Rettifica', 'Sposta']
      const unitaMisura = ['KG', 'CAD']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i += i + 1) {
        const code = 10000
        tradeData.push({
          id: code + i,
          nome:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          unitaMisura: unitaMisura[Math.floor(Math.random() * unitaMisura.length)],
          quantita: Math.floor(Math.random() * location.length),
          idFornitore: Math.floor(Math.random() * location.length) + 'ABC',
          idMagazzino: Math.floor(Math.random() * location.length) + 'ABC',
          codiceProdotto: Math.floor(Math.random() * location.length) + 'TRR',
          prezzoDiVendita: Math.floor(Math.random() * location.length * 5),
          lottoSeriale: Math.floor(Math.random() * location.length) + 'TRR' + 'AEA',
          dataMovimento: new Date(new Date().getTime() + Math.random() * (new Date().getTime() - new Date().getTime())),
          causaleMovimento: 'Prova causale 123',
          causaleTrasporto: 'Prova causale 123',
          tipoMovimneto: tipoMovimento[Math.floor(Math.random() * tipoMovimento.length)],
          quantitaArrivo: Math.floor(Math.random() * location.length * 100),
          importoRiga: Math.floor(Math.random() * location.length * 100),
          agente: employees[Math.floor(Math.random() * employees.length)],
        })
      }
      this.stTime = performance.now()
      return this.products
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filterType() {
      if (this.selectType !== '') {
        return this.rows.filter(r => r.type.toLowerCase() === this.selectType.toLowerCase())
      }
      return this.rows
    },
  },
  mounted() {
    this.$refs.overviewgridProdottiMagazzino.ej2Instances.toolbarModule.enableItems(['addProduct'], false)
  },
  methods: {
    setSingleProduct(bool) {
      this.isSingleProduct = bool
    },
    setMagazzinoProdotti(magazzino) {
      this.magazzinoProdottiId = magazzino
      // console.log('lol', this.magazzinoProdottiId)
    },
    showDetails(params) {
      this.$router.push({ name: 'details-products', params: { id: params.row.id } })
    },
    showDetailsFromButton(row) {
      this.$router.push({ name: 'details-products', params: { id: row.id } })
    },
    filterColumns(columns) {
      columns.push('action')
      this.columnsFiltered = this.columns.filter(col => columns.includes(col.field))
    },
    selectionChanged(params) {
      // console.log(params.selectedRows)
      if (params.selectedRows.length !== 0) {
        this.rowsSelected = [...params.selectedRows]
        this.showButtonsOption = true
        return false
      }
      this.showButtonsOption = false
      return false
    // params.selectedRows - all rows that are selected (this page)
    },
    duplicateRows() {
      this.rows = [...this.rowsSelected, ...this.rows]
      /* this.rowsSelected.forEach((_, index) => {
        this.rowsSelected[index].vgtSelected = false
      })
      console.log(this.rowsSelected) */
    },
    deleteRows() {
      /* this.rows.foreach((element, index) => {

      })
      console.log(this.rowsSelected) */
    },
    addSelectedRows() {
      const data1 = [...this.rowsSelected]
      data1.forEach((_, index) => {
        data1[index].vgtSelected = false
      })
      this.$emit('add-rows-to-group', this.rowsSelected, this.dataTitle)
      this.$refs['add-product-to-group'].hide()
    },
    setTitle(title) {
      this.title = title
    },
    getTitle() {
      return this.title
    },
    openSidebar() {
      this.show = true
    },
    onDeselectRow() {
      if (this.$refs.overviewgridProdottiMagazzino.getSelectedRecords().length === 0) {
        this.$refs.overviewgridProdottiMagazzino.ej2Instances.toolbarModule.enableItems(['addProduct'], false)
      }
    },
    onRowSelected() {
      this.$refs.overviewgridProdottiMagazzino.ej2Instances.toolbarModule.enableItems(['addProduct'], true)
      this.selectedRow = [...this.$refs.overviewgridProdottiMagazzino.getSelectedRecords()]
      // console.log(this.selectedRow)
    },
    pushSelected(products) {
      // this.selectedRow = [...this.$refs.overviewgridProdottiMagazzino.getSelectedRecords()]
      this.$emit('add-selected-product', products)
      this.show = false
      this.selectedRow.splice(0)
      this.$refs.overviewgridProdottiMagazzino.clearSelection()
      /* this.selectedRow = [...this.$refs.overviewgridProdottiMagazzino.getSelectedRecords()]
      this.$emit('add-selected-product', [...this.selectedRow])
      this.show = false
      this.selectedRow.splice(0)
      this.$refs.overviewgridProdottiMagazzino.clearSelection() */
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'addProduct') {
        // console.log(args)
        this.selectedRow = [...this.$refs.overviewgridProdottiMagazzino.getSelectedRecords()]
        this.pushSelected(this.selectedRow)
        // console.log(this.$refs.overviewgridProdottiMagazzino.getSelectedRecords())
        // this.$refs.overviewgridProdottiMagazzino.ej2Instances
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: 'prova.pdf',
        }
        this.$refs.overviewgridProdottiMagazzino.pdfExport(exportProperties)
        // this.$refs.overviewgridProdottiMagazzino.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgridProdottiMagazzino.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgridProdottiMagazzino.csvExport()
      }
    },
    actionTableBegin(action) {
    },
    actionTableComplete(action) {
    },
    commandColumm(args) {
      // console.log(args)
      if (args.commandColumn.id === 'addProduct') {
        this.selectedRow = [{...args.rowData}]
        this.pushSelected(this.selectedRow )
      }
    },   
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, Page, ColumnChooser, CommandColumn, Resize, Scroll, Filter],
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
