<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <b-row class="d-flex justify-content-end">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('CARICO')"
        >
          Carica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SCARICO')"
        >
          Scarica
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn mr-2"
          @click="selectedOp('SPOSTA')"
        >
          Sposta
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="myBtn"
          @click="selectedOp('RETTIFICA')"
        >
          Rettifica
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <filters-badge-syncfusion
          :filtersUsed="filtersSearchBegin"
          @clear-filter="clearFilter"
        />
      </b-col>
    </b-row>
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgridflowshop"
          ref="overviewgridflowshop"
          locale="it-IT"
          :data-source="movimentiMagazzino"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterSettings"
          :text-wrap-settings="wrapSettings"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="dataOperazione"
              header-text="Data"
              width="180"
              :filter="filterdate"
              :format="{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }"
              clip-mode="EllipsisWithTooltip"
              :template="templateDataOperazione"
            />
            <e-column
              field="tipoMovimento"
              header-text="Tipo"
              width="150"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
              :template="templateTipoMovimento"
            />
            <e-column
              field="descrizione"
              header-text="descrizione"
              width="400"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="codiceProdotto"
              header-text="Cod.Prod."
              width="130"
              clip-mode="EllipsisWithTooltip"
              :template="templateCodiceProdotto"
            />
            <e-column
              field="nomeProdotto"
              header-text="Nome Prodotto"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            /><!-- :template="templateNomeProdotto" -->
            <e-column
              field="quantita"
              header-text="quantità"
              width="100"
              :filter="filter"
            /><!-- :template="templateQuantita" -->
            <e-column
              field="unitaMisura"
              header-text="U.M"
              width="80"
              :filter="filter"
            /><!-- :template="templateUnitaMisura" -->
            <e-column
              field="tipoProdotto"
              header-text="Tipo Prodotto"
              width="160"
              :filter="filter"
              :template="templateTipoProdotto"
            />
            <e-column
              field="nomeMagazzino"
              header-text="Magazzino"
              width="200"
              :filter="filter"
              :template="templateMagazzino"
            />
            <e-column
              field="codiceMagazzino"
              header-text="Cod.Magazzino"
              width="200"
              :filter="filter"
              :template="templateCodMagazzino"
              :visible="false"
            />
            <!-- <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            /> -->
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <WarehouseOperations
      ref="flow-warehouses-event-handler"
      @update-flow-warehouse="updateMovimenti"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import {
  DateRangePicker,
  DateRangePickerPlugin
} from "@syncfusion/ej2-vue-calendars"
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base'
import { BCard, BButton, BRow, BCol, VBToggle, BAvatar, BBadge } from 'bootstrap-vue'
// import SidebarFlowWarehouseEventHandler from './eventHandlerSidebar/SidebarFlowWarehouseEventHandler.vue'
// import SettingsPdfExport from '@/components/pdfExportModal/SettingsPdfExport.vue'
import FiltersBadgeSyncfusion from '@/@core/components/filter-table-syncfusion/FiltersBadgeSyncfusion.vue'
import WarehouseOperations from '@/views/pages/portfolio/products/details/sidebarEventHandler/WarehouseOperations.vue'
import { getMovimentiMagazzino } from '@/@core/api-service/logistic/flowWarehouse'
import { getPuntoVendita } from '@/@core/api-service/logistic/puntiVendita'
import { createElement } from "@syncfusion/ej2-base"
import moment from 'moment'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as gregorian from 'cldr-data/main/it/ca-gregorian.json'
import * as numbers from 'cldr-data/main/it/numbers.json'
import * as timeZoneNames from 'cldr-data/main/it/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json' // To load the culture based first day of week

Vue.use(GridPlugin)
Vue.use(DateRangePickerPlugin)
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData)

/* L10n.load({
  'it': {
      'daterangepicker': {
        placeholder: 'Seleziona date',
        startLabel: 'Data Inizio',
        endLabel: 'Data Fine',
        applyText: 'Conferma',
        cancelText: 'Annulla',
        selectedDays: 'Giorno selezionati',
        days: 'Giorni',
        customRange: 'Data Personalizzata',
    },
  }
}) */
export default Vue.extend({
  components: {
    BCard,
    BButton,
    // SettingsPdfExport,
    // SidebarFlowWarehouseEventHandler,
    BRow,
    BCol,
    WarehouseOperations,
    FiltersBadgeSyncfusion,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    let dateRangeInstance = null;
    let dateValue = "";
    let customFilter = false;
    let vm = this;
    return {
      filtersSearchBegin: [],
      filterSettings: { type: "Menu" },
      filterdate: {
        ui: {
          create: function(args) {
            args.getOptrInstance.dropOptr.element.parentElement.parentElement.style.display =
              "none";
            var isFiltered = event.target.classList.contains("e-filtered");
            vm.dateRangeInstance = new DateRangePicker({
              placeholder: 'Seleziona date',
              value: isFiltered ? vm.dateValue : '',
              change: function(e) {
                if (e.value) {
                  // Initially ‘greaterthan’ filter is performed for start date value
                  vm.dateValue = e.value;
                  vm.$refs.overviewgridflowshop.filterByColumn(
                    "dataOperazione",
                    "greaterthanorequal",
                    vm.dateValue[0]
                  );
                } else {
                  vm.$refs.overviewgridflowshop.filterSettings.columns = [];
                  vm.$refs.overviewgridflowshop.removeFilteredColsByField(args.target.id);
                }
              }
            });
            let flValInput = createElement("input", { className: "flm-input" });
            args.target.appendChild(flValInput);
            vm.dateRangeInstance.appendTo(flValInput);
            // console.log(vm.dateRangeInstance)
          },
          write: function() {
              // console.log("filterval:" + args.filteredValue);
          },
          read: function() {
            // console.log('read', args);
          }
        }
      },
      selectedOperation: '',
          cTemplate: function () {
        return { template : Vue.component('columnTemplate',{
            template: `<div class="image">
                  <img :src="image" :alt="altImage"/>
              </div>`,
              data: function() {
                  return {
                      data: {}
                  }
              },
              computed: {
                  image: function() {
                      return './' + this.data.EmployeeID + '.png'
                  },
                  altImage: function() {
                      return this.data.EmployeeID
                  }
              }
        })
      }
    },
    warehouse: {},
    wrapSettings: { wrapMode: 'Content' },
    commands: [
      { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
      { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
      { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-settings', cssClass: 'e-flat' } }
    ],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy','PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup'],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: false, mode: 'Normal' },
    toolbar: ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    movimentiMagazzino: [],
    rows: [
      {
        id: 1,
        nome: 'Croccantini',
        unitaMisura: 'KG',
        quantita: 100,
        idFornitore: 'fornitrore2',

      },
    ],
    templateTipoMovimento() {
      return {
        template: Vue.component('templateTipoMovimento', {
        components: {
              BAvatar,
            },
            data() { return { data: {} } },
            template: `
              <div
                v-if="data.tipoMovimento === 'CARICO'"
              >
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'success'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
              <div v-else-if="data.tipoMovimento === 'SCARICO'">
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'danger'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
              <div v-else-if="data.tipoMovimento === 'RETTIFICA'">
                <div class="d-flex justify-content-between">
                  <div class="d-flex justify-content-center"><p class="my-auto">{{ data.tipoMovimento }}</p></div>
                  <b-avatar
                    :variant="'primary'"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="ArrowUpRightIcon"
                      size="16"
                    />
                  </b-avatar>
                </div>
              </div>
            `
          }),
        }
    },
    templateMagazzino() {
        return {
          template: Vue.component('templateMagazzino', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="primary"
            >
              {{ data.nomeMagazzino }}
            </b-badge>`,
          }),
        }
      },
    templateDataOperazione() {
        return {
          template: Vue.component('templateDataOperazione', {
            components: {
            },
            data() { return { data: {} } },
            computed: {
              dataOperatizioneComputed() {
                return moment(this.data.dataOperazione).format("DD/MM/YYYY HH:mm:ss")
              }
            },
            template: `
            <div>
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              {{ dataOperatizioneComputed }}
            </div>
            `,
          }),
        }
      },
    templateCodMagazzino() {
        return {
          template: Vue.component('templateCodMagazzino', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="primary"
            >
              {{ data.codiceMagazzino }}
            </b-badge>`,
          }),
        }
      },
    templateTipoProdotto() {
        return {
          template: Vue.component('templateTipoProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.tipoProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateNomeProdotto() {
        return {
          template: Vue.component('templateNomeProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.nomeProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateCodiceProdotto() {
        return {
          template: Vue.component('templateCodiceProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.codiceProdotto }}
            </b-badge>`,
          }),
        }
      },
    templateUnitaMisura() {
        return {
          template: Vue.component('templateUnitaMisura', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="danger"
            >
              {{ data.unitaMisura }}
            </b-badge>`,
          }),
        }
      },
    templateQuantita() {
        return {
          template: Vue.component('templateQuantita', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="danger"
            >
              {{ data.quantita }}
            </b-badge>`,
          }),
        }
      },
    templateCodiceProdotto() {
        return {
          template: Vue.component('templateCodiceProdotto', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            template: `
            <b-badge
              variant="info"
            >
              {{ data.codiceProdotto }}
            </b-badge>`,
          }),
        }
      },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'Menu',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
    }
  },
  mounted() {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    getMovimentiMagazzino().then(res => {
      if (res.data.esito === 'OK') {
        this.movimentiMagazzino = res.data.movimentoMagazzino.map(el => {
          const movimento = {
            codiceMagazzino: el.codiceMagazzino,
            codiceProdotto: el.codiceProdotto,
            codicePuntovendita: el.codicePuntovendita,
            dataOperazione: new Date(el.dataOperazione),
            // dataOperazione: new Date(el.dataOperazione).toLocaleString('it-IT', options),
            descrizione: el.descrizione,
            id: el.id,
            idDcoumento: el.idDcoumento,
            idFornitore: el.idFornitore,
            idMagazzino: el.idMagazzino,
            idProdotto: el.idProdotto,
            idProduttore: el.idProduttore,
            idPuntovendita: el.idPuntovendita,
            importoRiga: el.importoRiga,
            iva: el.iva,
            nomeMagazzino: el.nomeMagazzino,
            nomeProdotto: el.nomeProdotto,
            note: el.note,
            prezzo: el.prezzo,
            quantita: el.quantita,
            tipoMovimento: el.tipoMovimento,
            unitaMisura: el.unitaMisura,
            tipoProdotto: el.tipoProdotto,
          }
          return movimento
        }).filter(el => Number(el.idPuntovendita) === Number(this.$route.params.id) ).reverse()
      }
    }).catch(e => e)
  },
  computed: {
  },
  methods: {
    clearFilter(field) {
      this.$refs.overviewgridflowshop.clearFiltering([field])
      const indexToRemove = this.filtersSearchBegin.findIndex(el => el.field === field)
      this.filtersSearchBegin[indexToRemove].active = false
    },
    updateMovimenti(movimenti) {
      // console.log(movimenti)
      movimenti.forEach(el => {
        this.movimentiMagazzino.unshift({ ...el })
      })
      // console.log(this.movimentiMagazzino)
      this.$refs.overviewgridflowshop.refresh()
    },
    selectedOp(operation) {
      this.$refs['flow-warehouses-event-handler'].setTitle(operation)
      this.$refs['flow-warehouses-event-handler'].openSidebar()

      // this.selectedOperation = operation
    },
    dataBound: function() {
      // console.log(this.$refs.overviewgridflowshop)
      this.$refs.overviewgridflowshop.autoFitColumns(['Azioni' ]);
    },
    commandColumm(args) {
      if(args.commandColumn.type === 'Dettagli') {
        this.$refs['flow-warehouses-event-handler'].setTitle('Modifica Movimento')
        this.$refs['flow-warehouses-event-handler'].openSidebar()
      }
    },
    contextMenuClick(args) {
      // console.log('contextMenuClick', args)
      if(args.item.id === 'Dettagli') {
        this.$refs['flow-warehouses-event-handler'].setTitle('Modifica Magazzino')
        this.$refs['flow-warehouses-event-handler'].setTitle('Modifica Movimento')
        this.$refs['flow-warehouses-event-handler'].openSidebar()
      }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin(args) {
      // console.log(action)
      if (
        args.requestType === "filtering" &&
        args.currentFilteringColumn === "dataOperazione"
      ) {
        // End date value is added as additional filter value with ‘lessthan’ filter operator
        args.columns.push({
          actualFilterValue: {},
          actualOperator: {},
          field: "dataOperazione",
          ignoreAccent: false,
          isForeignKey: false,
          matchCase: false,
          operator: "lessthan",
          predicate: "and",
          uid: this.$refs.overviewgridflowshop.getColumnByField(
            args.currentFilteringColumn
          ).uid,
          value: this.dateValue[1]
        });
      }
    },
    actionTableComplete(args) {
      if (args.action === 'filter') {
        const columnInfo = this.$refs.overviewgridflowshop.getColumnByField(String(args.currentFilteringColumn))
        let columnFiltered = {}
        if (args.currentFilteringColumn === 'dataOperazione') {
          columnFiltered = {
            field: columnInfo.field,
            headerText: columnInfo.headerText,
            value: String(moment(args.columns[0].value).format('DD/MM/YYYY')).concat(' - ', String(moment(args.columns[1].value).format('DD/MM/YYYY'))),
            active: true,
          }
        } else {
          console.log('sono su else')
          columnFiltered = {
            field: columnInfo.field,
            headerText: columnInfo.headerText,
            value: args.currentFilterObject.value,
            active: true,
          }
        }
        // const headerTextFilterUsed = allColumns.find(el => el.field === args.currentFilteringColumn)
        const esistenzaFilter = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilteringColumn)
        if (esistenzaFilter >= 0) {
          this.filtersSearchBegin[esistenzaFilter].value = args.currentFilterObject.value
          this.filtersSearchBegin[esistenzaFilter].active = true
        } else {
          this.filtersSearchBegin.push(columnFiltered)
        }
      }
      if (args.action === 'clearFilter') {
        const indexSearch = this.filtersSearchBegin.findIndex(el => el.field === args.currentFilterColumn.field)
        if (indexSearch >= 0) {
          this.filtersSearchBegin[indexSearch].active = false
        }
      }
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
    async toolbarClick(args) {
      // console.log(args)
      const id = JSON.stringify([{ id: this.$route.params.shop }])
      let nameShop = ''
      await getPuntoVendita(id).then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          nameShop = res.data.puntovendita.nome
        }
      }).catch(e => e)
      if (args.item.id === 'Dettagli') {
        alert("Custom Toolbar Click...");
      }
      if (args.item.id === 'overviewgridflowshop_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Movimenti_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.pdf`,
            }
          // this.$bvModal.show('setting-pdf-export-modal')
          this.$refs.overviewgridflowshop.pdfExport(exportProperties)
          // this.$refs.overviewgridflowshop.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgridflowshop_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Movimenti_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.xlsx`,
            }
          this.$refs.overviewgridflowshop.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgridflowshop_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `Movimenti_${nameShop.trim()}_${moment().format('DD-MM-YYYY')}.csv`,
            }
          this.$refs.overviewgridflowshop.csvExport(exportProperties)
      }
    },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgridflowshop.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgridflowshop.ej2Instances.contentModule.content
      this.$refs.overviewgridflowshop.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgridflowshop.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgridflowshop.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgridflowshop.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgridflowshop.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
    addFlowWarehouse(warehouse, title) {
      this.$refs['flow-warehouses-event-handler'].setTitle(title)
      this.$refs['flow-warehouses-event-handler'].openSidebar()

    },
    prepareSidebarToEdit(user, title) {
      // console.log(this.collaborators)
      if (title === 'Modifica Magazzino') {
        this.titleCollaboratorEventHandler = title
        const roles = user.ruolo.split('/')
        const userEdited = { ...user }
        userEdited.ruolo = [...roles]
        // console.log(userEdited)
        this.collaborator = { ...userEdited }
        // console.log('collaborator', this.collaborator)
      } else {
        this.titleCollaboratorEventHandler = title
        this.warehouse = {
          avatar: '',
          nome: '',
          cognome: '',
          ruolo: '',
          email: '',
          codice_fiscale: '',
          partita_iva: '',
          stato: '',
          cellulare: '',
          ACL: '',
          data_nascita: '',
          stato_nascita: '',
          prov_nascita: '',
          citta_nascita: '',
          sesso: '',
          codice_sdi: '',
          ragione_sociale: '',
        }
      }
      // console.log('prepareSidebarToEdit', user)
      // console.log('prepareSidebarToEdit', title)
      // console.log('editCustomer', user)
      // console.log('collaborator', this.collaborator)
      this.$refs['sidebar-collaborators'].setFormToEdit(this.collaborator)
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
