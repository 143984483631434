<template>
  <b-sidebar
    id="warehouse-operations"
    ref="warehouse-operations"
    v-model="show"
    sidebar-class="sidebar-40"
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    :no-close-on-backdrop="true"
    shadow
    no-header
    right
    @hidden="resetSidebar"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h6>{{ title }}</h6>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide(); show = false"
          />
        </div>
      </div>
      <validation-observer ref="form-warehouse-opearation-observer">
        <b-row class="m-1">
          <b-col
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="title === 'Carica' ? 'Magazzino di Carico' : title === 'Scarica' ? 'Magazzino di Scarico' : title === 'Sposta' ? 'Magazzino Prelievo' : 'Magazzino'"
              rules="required"
            >
              <b-form-group
                :label="title === 'Carica' ? 'Magazzino di Carico' : title === 'Scarica' ? 'Magazzino di Scarico' : title === 'Sposta' ? 'Magazzino Prelievo' : 'Magazzino'"
                label-for="warehouse-selection"
                label-class="font-weight-bolder"
              >
                <v-select
                  v-model="movimentazione.idMagazzinoPartenza"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nome"
                  :options="optionType"
                  :reduce="option => option.id"
                  :placeholder="title === 'Carica' ? 'Magazzino di Carico' : title === 'Scarica' ? 'Magazzino di Scarico' : title === 'Sposta' ? 'Magazzino Prelievo' : 'Magazzino'"
                  @open="getMagazziniFromServer"
                />
              </b-form-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- <b-col
            v-if="title === 'Carica'"
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="'Fornitore'"
              label-for="warehouse-selection"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="movimentazione.provenienzaMerce"
                :clarable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :reduce="option => option.id"
                :options="optionType"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            v-if="title === 'SPOSTA'"
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="'Magazzino Destinatario'"
              label-for="warehouse-selection"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Magazzino Destinatario"
                rules="required"
              >
                <v-select
                  v-model="movimentazione.idMagazzinoDestinazione"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nome"
                  :reduce="option => option.id"
                  :options="optionType"
                  placeholder="Magazzino Destinatario"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col
            v-if="title === 'Scarica'"
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="'Destinazione'"
              label-for="warehouse-selection"
              label-class="font-weight-bolder"
            >
              <b-form-input
                v-model="movimentazione.destinazioneMerce"
                placeholder="destinazione merce"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row class="m-1">
          <b-col
            cols="6"
            lg="4"
          >

            <b-form-group
              label="Data Operazione"
              label-for="warehouse-date-operation"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Operazione"
                rules="required"
              >
                <flat-pickr
                  id="warehouse-date-operation"
                  v-model="movimentazione.dataOperazione"
                  class="form-control"
                  name="birth-date"
                  :config="configFlatPicker"
                  :placeholder="'data operazione'"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="title === 'Rettifica'"
          class="m-1"
        >
          <b-col
            cols="6"
            lg="5"
          >
          <validation-provider
              #default="{ errors }"
              name="Rettifica"
              rules="required"
            >
              <b-form-group
                :label="'Giacenza Attuale'"
                label-for="product-warehouse-quantity-now"
                label-class="font-weight-bolder"
              >
                <b-input-group append="pz/kg">
                  <b-form-input
                    id="product-warehouse-quantity-now"
                    placeholder="Giacenza Attuale"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
          </validation-provider>
          </b-col>
          <b-col
            cols="6"
            lg="5"
          >
            <b-form-group
              :label="'Nuova Giacenza'"
              label-for="product-warehouse-quantity-new"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Nuova Giacenza"
                rules="required"
              >
                <b-input-group append="pz/kg">
                  <b-form-input
                    id="product-warehouse-quantity-new"
                    placeholder="Nuova Giacenza"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col
            cols="12"
          >
            <b-form-group
              :label="'Causale'"
              label-for="product-causale"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Causale"
                rules="required"
              >
              <b-form-textarea
                v-model="movimentazione.descrizione"
                id="product-causale"
                placeholder="causale"
                rows="2"
              />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          class="m-1"
        >
          <b-col>
            <ejs-grid
              id="overviewgrid"
              ref="overviewgrid"
              :data-source="movimentazione.prodotti"
              :selection-settings="selectionSettings"
              :edit-settings="editSettings"
              :filter-settings="filterOptions"
              :allow-filtering="true"
              :show-column-chooser="true"
              :allow-selection="true"
              :allow-sorting="true"
              :allow-resizing="true"
              :enable-virtualization="false"
              :allow-row-drag-and-drop="true"
              height="300"
              :allow-excel-export="true"
              :allow-pdf-export="true"
              row-height="38"
              :enable-hover="false"
              :enable-header-focus="true"
              :toolbar="toolbar"
              :toolbar-click="toolbarClick"
              :action-begin="actionTableBegin"
              :action-complete="actionTableComplete"
              :context-menu-items="contextMenuItems"
            >
              <e-columns>
                <e-column
                  type="checkbox"
                  :allow-filtering="false"
                  :allow-sorting="false"
                  :allow-editing="false"
                  width="70"
                />
                <e-column
                  field="id"
                  header-text="id"
                  :is-primary-key="true"
                  width="200"
                  :visible="false"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="codice"
                  header-text="Codice"
                  width="100"
                  :filter="filter"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="nome"
                  header-text="Nome"
                  width="200"
                  :filter="filter"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="qnt"
                  header-text="QNT"
                  width="100"
                  :filter="filter"
                  :validation-rules="customRulesQuantityOfOperations"
                />
                <e-column
                  field="quantita"
                  header-text="QNT Attuale"
                  width="150"
                  :filter="filter"
                  :allow-editing="false"
                />
                <e-column
                  field="prezzoVendita"
                  header-text="Prezzo"
                  width="100"
                  clip-mode="EllipsisWithTooltip"
                  :allow-editing="false"
                />
                <e-column
                  field="aliquotaIva"
                  header-text="Iva"
                  width="200"
                  clip-mode="EllipsisWithTooltip"
                  :visible="false"
                  :allow-editing="false"
                />
                <e-column
                  field="costo"
                  header-text="Costo"
                  width="80"
                  :allow-editing="false"
                  :visible="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="unitaUtilizzo"
                  header-text="Unità Utilizzo"
                  width="80"
                  :visible="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="unitaMisura"
                  header-text="UM"
                  width="80"
                  clip-mode="EllipsisWithTooltip"
                  :allow-editing="false"
                />
                <e-column
                  field="statoServizio"
                  header-text="Stato"
                  width="170"
                  :allow-editing="false"
                  :visible="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="tipoProdotto"
                  header-text="Tipologia"
                  width="140"
                  :allow-editing="false"
                  :visible="false"
                  :filter="filter"
                />
                <e-column
                  field="attivo"
                  header-text="Attivo"
                  width="100"
                  :filter="filter"
                  :template="templateAttivo"
                  text-align="Center"
                  :allow-editing="false"
                  :visible="false"
                />
                <e-column
                  field="disponibile"
                  header-text="Disponibile"
                  width="100"
                  :filter="filter"
                  :template="templateDisponibile"
                  text-align="Center"
                  :visible="false"
                  :allow-editing="false"
                />
                <e-column
                  field="inEvidenza"
                  header-text="In Evidenza"
                  width="100"
                  :filter="filter"
                  :template="templateInEvidenza"
                  text-align="Center"
                  :visible="false"
                  :allow-editing="false"
                />
                <e-column
                  field="visibile"
                  header-text="Visibile"
                  width="100"
                  :filter="filter"
                  :template="templateVisibile"
                  text-align="Center"
                  :visible="false"
                  :allow-editing="false"
                />
                <e-column
                  field="descrizioneBreve"
                  header-text="Descrizione"
                  width="170"
                  :filter="filter"
                  :visible="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="Azioni"
                  :lock-column="true"
                  header-text="Azioni"
                  width="150"
                  :allow-editing="false"
                  :commands="commands"
                  :allow-sorting="false"
                  :allow-filtering="false"
                  hide-at-media="(min-width:900px)"
                />
              </e-columns>
            </ejs-grid>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end mt-2"
          >
            <b-button
              variant="primary"
              :disabled="buttonSaveDisabled"
              @click="saveMovimento"
            >
              Salva
            </b-button>
          </b-col>
          <!-- {{ movimentazione }} -->
        </b-row>
      </validation-observer>
      <!-- <b-row
        v-if="$route.name === 'details-products'"
        no-gutters
        class="m-1"
      >
        <b-col cols="12">
          <p>
            {{ getProduct.nome }}
          </p>
          <p>
            {{ getProduct.codice }}
          </p>
        </b-col>
      </b-row> -->
      <SidebarProductEventHandler
        ref="sidebar-product-event-handler"
        @add-selected-product="addSelectProduct"
        :id-product-locked="{ id: getProduct.id }"
      />
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
        class="z-indexAlert"
      />
    </template>
  </b-sidebar>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  GridPlugin, Sort, Filter, Selection, Toolbar, Edit, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
import {
  BSidebar, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BFormTextarea, VBToggle, BAvatar, BButton,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import SidebarProductEventHandler from '@/components/sidebar/SidebarProductEventHandler.vue'
import { getMagazzini } from '@/@core/api-service/logistic/warehouses'
import { setMovimentiMagazzino } from '@/@core/api-service/logistic/flowWarehouse'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)

export default Vue.extend({
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    flatPickr,
    BFormTextarea,
    SidebarProductEventHandler,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      idProductLocked: { id: 0 },
      // customRulesQuantityOfOperationsRettifica: { requirend: [true, 'Campo Obbligatorio'], range: [[-9999, 9999], 'Inserire valori da -9999 a 9999'] },
      buttonSaveDisabled: true,
      movimentazione: {
        idMagazzinoPartenza: '',
        idMagazzinoDestinazione: '',
        dataOperazione: new Date(),
        prodotti: [],
        descrizione: '',
        idFornitore: 0,
        tipoMovimento: '',
      },
      show: false,

      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      toolbar: ['Delete', 'ColumnChooser', 'Print', {
        text: 'Aggiungi Prodotto', tooltipText: 'Aggiungi Prodotto', prefixIcon: 'e-expand', id: 'addProduct',
      }],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Edit', 'Delete', 'Save', 'Cancel',
        'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup', {
          text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open',
        }],
      title: '',
      optionType: [],
      specialPricesToStart: '2021/12/20',
      specialPricesToEnd: '2021/12/29',
      locale: 'it',
      configFlatPicker: {
        static: false,
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'M-j-Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
      commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
        {
          type: 'Aggiungi',
          id: 'Aggiungi',
          target: '.e-column',
          buttonOption: { iconCss: 'e-icons e-folder-open', cssClass: 'e-flat' },
        },
      ],
      templateAttivo() {
        return {
          template: Vue.component('templateAttivo', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.attivo == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.attivo == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateDisponibile() {
        return {
          template: Vue.component('templateDisponibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.disponibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.disponibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateInEvidenza() {
        return {
          template: Vue.component('templateInEvidenza', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.inEvidenza == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.inEvidenza == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
      templateVisibile() {
        return {
          template: Vue.component('templateVisibile', {
            components: {
              BAvatar,
            },
            data() { return { data: {}, item: [1, 2, 3, 4, 5] } },
            template: `
            <div id="producter" class="">
              <b-avatar :variant="data.visibile == '1' ? 'success' : 'danger'">
              <feather-icon
                :icon="data.visibile == '1' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
            </div>`,
          }),
        }
      },
    }
  },
  watch: {
    movimentazione: {
      handler() {
        // console.log('allroutes', this.allRoutes)
        // console.log('wow', this.movimentazione)
        this.$refs['sidebar-product-event-handler'].setMagazzinoProdotti(this.movimentazione.idMagazzinoPartenza)
      },
      deep: true,
    },
  },
  computed: {
    getProduct() {
      return this.$store.getters['products/getProductGeneral']
    },
    getTradeData() {
      // console.log('computed', this.prodotti)
      return this.movimentazione.prodotti
    },
    titleComputed() {
      return this.title
    },
    customRulesQuantityOfOperations() {
      if (this.title !== 'RETTIFICA') {
        return { required: [true, 'Campo Obbligatorio'], range: [[0, 9999], 'Inserire valori maggiori di 0'] }
      }
      return { required: [true, 'Campo Obbligatorio'], range: [[-9999, 9999], 'Inserire valori tra -9999 e 9999'] }
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    customValidatorRettifica() {
      if (this.title === 'RETTIFICA') {
        return [-9999, 9999]
      }
      return [9999, 9999]
    },
    saveMovimento() {
      this.$refs['form-warehouse-opearation-observer'].validate().then(success => {
        if (success) {
          this.movimentazione.tipoMovimento = this.title.toUpperCase()
          if (this.movimentazione.tipoMovimento === 'CARICO' || this.movimentazione.tipoMovimento === 'SCARICO' || this.movimentazione.tipoMovimento === 'RETTIFICA') {
            this.movimentazione.idMagazzinoDestinazione = 0
          }
          const movimentazioneTemp = { ...this.movimentazione }
          movimentazioneTemp.prodotti = this.movimentazione.prodotti.map(el => ({
            id: el.id, quantita: el.qnt, prezzo: el.prezzoVendita, iva: Number(el.aliquotaIva),
          }))
          // console.log(movimentazioneTemp.prodotti)
          /* movimentazioneTemp.prodotti.foreach(el => {
            if (!el.quantita) {
              this.$refs.toastRef.show({
                title: 'Quantita Prodotto/i Obbligatoria', content: 'Devi inserire una quantità da movimentare', cssClass: 'e-toast-error', icon: 'e-danger toast-icons',
              })
              return false
            }
            return true
          }) */
          console.log('movimento', [{ ...movimentazioneTemp }])
          setMovimentiMagazzino([{ ...movimentazioneTemp }]).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              const movimenti = [...res.data.Movimenti]
              const movimentiMappato = movimenti.map(el => {
                const movimento = {
                  codiceMagazzino: el.codice_magazzino,
                  codiceProdotto: el.codice_prodotto,
                  codicePuntovendita: el.codice_puntovendita,
                  dataOperazione: new Date(el.data_operazione),
                  // dataOperazione: new Date(el.dataOperazione).toLocaleString('it-IT', options),
                  descrizione: el.descrizione,
                  id: el.id,
                  idDcoumento: el.id_docoumento,
                  idFornitore: el.id_fornitore,
                  idMagazzino: el.id_magazzino,
                  idProdotto: el.id_prodotto,
                  idProduttore: el.id_produttore,
                  idPuntovendita: el.id_puntovendita,
                  importoRiga: el.importo_riga,
                  iva: el.iva,
                  nomeMagazzino: el.nome_magazzino,
                  nomeProdotto: el.nome_prodotto,
                  note: el.note,
                  prezzo: el.prezzo,
                  quantita: parseFloat(el.quantita).toFixed(2),
                  tipoMovimento: el.tipo_movimento,
                  unitaMisura: el.unita_misura,
                  tipoProdotto: el.tipo_prodotto,
                }
                return movimento
              })
              this.$emit('update-flow-warehouse', movimentiMappato)
              this.$refs['warehouse-operations'].hide()
            }
          }).catch(e => e)
        }
      })
    },
    getMagazziniFromServer() {
      getMagazzini().then(res => {
        if (res.data.esito === 'OK') {
          this.optionType = [...res.data.magazzini]
        }
      })
    },
    addSelectProduct(products) {
      const productsActual = [...this.$refs.overviewgrid.ej2Instances.dataSource]
      productsActual.unshift(...products)
      this.$refs.overviewgrid.ej2Instances.dataSource = productsActual
      this.movimentazione.prodotti = [...productsActual]
    },
    setTitle(title) {
      this.title = title
    },
    openSidebar() {
      this.show = true
    },
    alertQuantityConfirm(index) {
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'La quantità inserita è minore di quella presente in magazzino, confermi?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (!res.value) {
          this.$refs.overviewgrid.selectRow(index)
          this.$refs.overviewgrid.startEdit()
          // se confermo cambiaù
        }
      })
    },
    actionTableBegin(args) {
      this.buttonSaveDisabled = true
      if (args.requestType === 'save') {
        // this.buttonSaveDisabled = false
        // console.log('actionTableBegin', args)
        // console.log(Number(args.data.quantita) - Number(args.data.qnt))
        // console.log(args.data.qnt)
        if (Number(args.data.quantita) - Number(args.data.qnt) < 0 && (this.title === 'SCARICO' || this.title === 'SPOSTA')) {
          this.alertQuantityConfirm(args.rowIndex)
        } else if (this.title === 'RETTIFICA' && args.data.qnt < 0) {
          this.alertQuantityConfirm(args.rowIndex)
        }
      }
    },
    actionTableComplete(args) {
      // console.log('actionComplete', args)
      if (args.requestType === 'save') {
        this.buttonSaveDisabled = false
      }
    },
    alertAndConfirm() {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'La quantità inserita è minore di quella presente in magazzino, confermi?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          // se confermo cambiaù
          // console.log('ho confermato')
        }
      })
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'addProduct') {
        if (this.movimentazione.idMagazzinoPartenza === '') {
          this.$refs.toastRef.show({
            title: 'Selezionare Magazzino!', content: 'Prima devi selezionare un magazzino', cssClass: 'e-toast-info', icon: 'e-info toast-icons',
          })
        } else {
          // this.$refs['add-product-to-group'].setTitle('Aggiungi Prodotto Correlato')
          this.$refs['sidebar-product-event-handler'].openSidebar()
        }
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: 'prova.pdf',
        }
        this.$refs.overviewgrid.pdfExport(exportProperties)
        // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid.csvExport()
      }
    },
    resetSidebar() {
      this.movimentazione.prodotti.length = 0
      this.movimentazione.idMagazzinoPartenza = ''
      this.movimentazione.descrizione = ''
      this.movimentazione.dataOperazione = new Date()
      this.$refs.overviewgrid.refresh()
    },
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, ColumnChooser, RowDD, Resize, Scroll, Aggregate, Edit, ContextMenu, CommandColumn],
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped>
#toast_type {
  z-index: 99999 !important;
}
</style>
